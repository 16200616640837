<div class="container-fluid mb-3">
    <div>
        <div class="row">
            <div class="col-12">
                <div class="page-title mb-2 pb-3 pt-3">
                    <h3 class="mb-0">Income Statement</h3>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-start sticky-header non-sticky py-2">
            <h3><span class="badge badge-dark">{{getDisplayTitle()}}</span></h3>
            <span *ngIf="entity?.company?.website" class="website-link-positioning">
              <a [href]=entity?.company?.website class="link-fontsize" target="_blank">
                {{companyService.getStripedWebsiteString(entity?.company?.website)}}</a>
            </span>
            <div class="ml-auto">
              <a [href]='entity?.task_backlink' target='_blank' *ngIf='entity?.task_backlink'>
                <div class="chip chip-inverse">
                  <con-icon-by-name iconName="external-link"></con-icon-by-name>
                  Task
                </div>
              </a>
              <div class="chip chip-inverse">
                <con-avatar [userId]="entity?.company?.manager?.id" [size]="32"></con-avatar>
                {{ entity?.company?.manager?.first_name }} {{ entity?.company?.manager?.last_name }}
              </div>
            </div>
        </div>

        <div class="alert ml-status-created" *ngIf="companyService.mlCurrentStatus() === MLSendStatus.CREATED">
          <con-icon-by-name iconName="gear"></con-icon-by-name> {{MLSendStatus.CREATED_MESSAGE}}
          <button class='btn btn-primary pull-right cancel-btn-pos' (click)='companyService.cancelRequest(this.entity.id)'>Cancel</button>
        </div>
        <div class="alert ml-status-in-progress" *ngIf="companyService.mlCurrentStatus() === MLSendStatus.IN_PROGRESS">
          <con-icon-by-name iconName="gear"></con-icon-by-name> {{MLSendStatus.IN_PROGRESS_MESSAGE}}
          <button class='btn btn-primary pull-right cancel-btn-pos' (click)='companyService.cancelRequest(this.entity.id)'>Cancel</button>
        </div>
        <div class="alert ml-status-submitted" *ngIf="companyService.mlCurrentStatus() === MLSendStatus.SUBMITTED">
          <con-icon-by-name iconName="gear"></con-icon-by-name> {{MLSendStatus.SUBMITTED_MESSAGE}}
        </div>
        <div class="alert ml-status-closed" *ngIf="companyService.mlCurrentStatus() === MLSendStatus.CLOSED">
          <con-icon-by-name iconName="check"></con-icon-by-name> {{MLSendStatus.CLOSED_MESSAGE}}
        </div>
        <div class="alert ml-status-failed" *ngIf="companyService.mlCurrentStatus() === MLSendStatus.FAILED">
          <con-icon-by-name iconName="gear"></con-icon-by-name> {{MLSendStatus.FAILED_MESSAGE}}
        </div>
        <con-loader *ngIf="loading || settingLock"></con-loader>

        <con-no-data *ngIf="entity?.locked && !settingLock && !loading" count="0" class="locked-report"></con-no-data>

        <div class="row" *ngIf="!settingLock">
            <div class="col-12">
                <div *ngIf="!loading">
                    <div class="card mb-2" *ngIf="!entity?.locked && !entitiesService.entityLockedByMl(entity)">
                        <div class="card-header" (click)="toggleType()">
                            <div class="d-flex justify-content-start align-items-center">
                                <con-icon-by-name iconName="database" [mutations]="['fw']" class="mr-1"></con-icon-by-name>New income
                                statement
                                <div class="card-header-toggle-individual ml-auto">
                                </div>
                            </div>
                        </div>
                        <div class="p-4 col-6">
                            <con-entity-form-content [resetKey]="resetKey" entityName="IncomeStatement"
                                [entity]="entityCompanyReport"
                                [parentCompany]="{id: entity?.company?.id, name: entity?.company?.name, report_type:entity?.report_type}"
                                [fixedValues]="{ company_report_id: entity?.id }" [showWhenChanged]="false"
                                [canDelete]="false" emptyAfterSave="true" (afterSave)="incomeStatementCreated($event)"
                                toastMessage="Income Statement added successfully!">
                            </con-entity-form-content>
                        </div>
                    </div>
                    <div *ngIf="!loading">
                        <div *ngFor="let incomeStatement of companyReport?.data; let index = index">
                            <div>

                                <div class="card mb-2" [ngClass]="incomeStatement?.is_fully_annotated !== null && !incomeStatement?.is_fully_annotated && incomeStatement?.is_fully_annotated !== undefined? 'annotation-error': ''">
                                    <div class="card-header"
                                        (click)="toggleType(incomeStatement); getIncomeSatementData(incomeStatement)">
                                        <div class="d-flex justify-content-start align-items-center">
                                            <con-icon-by-name iconName="database" [mutations]="['fw']" class="mr-1 cursor-pointer" (click)="companyService.goToEntity($event, 'income_statement', incomeStatement?.id)"></con-icon-by-name>
                                        {{getDisplayName(incomeStatement)}}
                                          <span class="badge badge-pill timeline-pill badge-primary ml-annotation-failed ml-2"
                                                ngbTooltip="Problem with the labeling of the report"
                                                *ngIf="incomeStatement?.is_fully_annotated !== null && !incomeStatement?.is_fully_annotated && incomeStatement?.is_fully_annotated !== undefined"
                                          >
                                            <con-icon-by-name [mutations]="['ml-status-black']" iconName="gear"></con-icon-by-name>
                                            NOT FULLY ANNOTATED
                                          </span>
                                            <div class="card-header-toggle-individual ml-auto">
                                                <con-icon-by-name iconName="angle-right"
                                                    [mutations]="getMutations(incomeStatement)"></con-icon-by-name>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-4 col-6" *ngIf="!incomeStatement?.hide">
                                        <con-entity-form-content [entity]="incomeStatement" entityName="IncomeStatement"
                                            [fixedValues]="{ company_report_id: entity?.id }" [showWhenChanged]="false"
                                            [canDelete]="false" (afterSave)="incomeStatementUpdated($event, index)"
                                            toastMessage="Income Statement updated successfully!"
                                            [canEdit]="!entity?.locked && !entitiesService.entityLockedByMl(entity)">
                                        </con-entity-form-content>
                                    </div>

                                    <con-loader *ngIf="incomeStatement?.loading"></con-loader>
                                    <div class="card-body mb-2"
                                        *ngIf="!incomeStatement?.hide && !incomeStatement?.loading">
                                        <div class="card-header">
                                            {{getISTitle(incomeStatement)}}
                                        </div>
                                        <div class="card-body col-xl-10 col-md-12"
                                            *ngIf="incomeStatement?.incomeData && incomeStatement?.incomeData?.modules">
                                          <div>
                                            <con-custom-search-select (onSelect)="appendPreviewUrl($event, incomeStatement)" [id]="incomeStatement?.company_report_id" ></con-custom-search-select>
                                            <con-ml-directory-preview [displayMLPreviewUrl]="incomeStatement?.previewUrl" (closePreviewFile)="setPreviewMLReportUrlEmpty(incomeStatement, $event)"></con-ml-directory-preview>
                                          </div>
                                            <con-tree-layout *ngIf="incomeStatement?.incomeData"
                                                [locked]="entity?.locked || entitiesService.entityLockedByMl(entity)"
                                                [reportLock]="entity?.locked" [entity]="entity"
                                                [incomeStatement]="incomeStatement?.incomeData"></con-tree-layout>

                                        </div>

                                    </div>
                                </div>
                                <con-no-data [count]="companyReport?.data?.length" [message]="noDataMessage">
                                </con-no-data>
                            </div>
                        </div>
                    </div>
                </div>

                <hr>
            </div>
        </div>
    </div>
</div>

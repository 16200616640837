import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CompanyReportsService } from 'app/company-reports/services/company-reports.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CompanyReportsSettings } from 'app/settings.class';
import { HttpClient } from '@angular/common/http';
import { snakeCase } from 'change-case';
import { MLSendStatus } from '../../models/MLSendStatus';
import { Observable, throwError } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { EntitiesService } from '../../../entities/services/entities.service';

@Component({
  selector: 'con-kpi-editor',
  templateUrl: './kpi-editor.component.html',
  styleUrls: ['./kpi-editor.component.scss']
})
export class KpiEditorComponent {

  @Input() kpi: any;
  @Input() entity: any;
  @Input() kpiType: any;

  private kpiInitialData: any;
  public processingTree = false;
  public mask: any = {
    mask: Number,
    thousandsSeparator: ' ',
    scale: 7,
    radix: '.',
    mapToRadix: [','],
    min: -999999999999999
  };
  public maxLimitErrorMessage: string;
  public editedFigures = [];
  public disableButtons = true;
  MLSendStatus = MLSendStatus;
  dateRangePicker = new UntypedFormControl();
  public previousYearValues: any = {};
  public previousValueSource: any = {};
  loadingData = false;

  constructor(
    public reportservice: CompanyReportsService,
    private toastr: ToastrService,
    private router: Router,
    private cd: ChangeDetectorRef,
    public entitiesService: EntitiesService,
    private http: HttpClient) {
  }

  saveKpiFigure(formObject) {
    if (formObject.valid) {
      this.processingTree = true;
      this.reportservice.saveEditordata({ editor_data: this.editedFigures }, this.kpi.id, snakeCase(this.kpiType)).subscribe((response: any) => {
        if (response) {
          response.modules.forEach(module => {
            if (module.value_rep !== null && module.value_rep !== undefined && module.value_rep !== '') {
              if (module.multiplier === 0.01) {
                module.display_value = (module.value_rep / module.multiplier).toFixed(4);
              } else {
                module.display_value = (module.value_rep / module.multiplier).toString();
              }
            } else {
              module.display_value = null;
            }
          });
          response.modules.forEach(module => {
            this.kpi.kpiData.modules.forEach(mod => {
              if (mod.module_id === module.module_id) {
                if (mod.prev_display_value) {
                  module.prev_display_value = mod.prev_display_value;
                  module.prev_year_value = mod.prev_year_value;
                }
              }
            });
          });
          this.kpiInitialData = JSON.parse(JSON.stringify(response));
          response = this.showPrevious(response)
          this.kpi.kpiData = JSON.parse(JSON.stringify(response));
          this.disableButtons = true;
          this._check();
          this.toastr.success('Data inserted successfully!', this.getKpiType() );
        } else {
          this.toastr.error('Sorry, some error occurred', this.getKpiType() );
        }
        this.processingTree = false;
      },
        err => {
          if(err.hasOwnProperty('type')){
            if(err.type === "LOCKED_ERROR" && !this.entity.locked){
              this.toastr.warning('ML data is being processed', 'ML Error');
            } else {
              this.toastr.warning(err.data.message);
            }
          }
          this.processingTree = false;
          if (err.status === 404) {
            this.router.navigate(['period_kpi', 'error'], { skipLocationChange: true });
          }
        });
    }

  }

  showPrevious(data: any) {
    if (data.past_modules && data.past_modules.length > 1) {
      data.showPrevious = true;
    } else {
      data.showPrevious = false;
    }
    return data;
  }

  getKpiType() {
    const kpiType = this.kpiType === 'PeriodKpi' ? 'Period KPI' : 'Snapshot KPI';
    return kpiType;
  }

  setInitialData() {
    for(let i =0; i< this.kpi.kpiData.modules.length; i++) {
      this.kpi.kpiData.modules[i].showCurrency = false;
      this.kpi.kpiData.modules[i].showLoader = false;
    }
    if(this.kpi.kpiData?.past_statement_id) {
      this.dateRangePicker.setValue(this.kpi.kpiData.past_statement_id);
    }
    this.kpiInitialData = JSON.parse(JSON.stringify(this.kpi.kpiData));
    this.cd.detectChanges();

  }

  onReset() {
    this.kpi.kpiData = JSON.parse(JSON.stringify(this.kpiInitialData));
    this.disableButtons = true;
  }

  valueChanged(module, valueCtrl) {
    if (module.display_value) {
      module.value_rep = module.display_value * module.multiplier;
      if (module.multiplier === '0.01') {
        module.value_rep = parseFloat(module.value_rep).toFixed(4);
      }
    } else {
      module.value_rep = null;
    }
    module.maxLimitErrorMessage = 'Please enter a value less than ' + 999999999999999.9999999 / module.multiplier;
    if (!module.figure_id) {
      this.pushToEditedModules({ id: module.module_id, value_rep: module.value_rep, is_dividend_currency_available: module.is_dividend_currency_available, currency: module.currency });
    } else {
      this.pushToEditedFigures({ id: module.figure_id, value_rep: module.value_rep, is_dividend_currency_available: module.is_dividend_currency_available, currency: module.currency });
    }
    if (this.checkNodeHasMaxValueError(module)) {
      valueCtrl.control.setErrors({ has_limit_error: true });
      this.toastr.error(module.maxLimitErrorMessage + ' ' + module.quantity, 'Limit exceeded');
    } else {
      valueCtrl.control.setErrors(null);
    }
    this.disableButtons = false;
  }
  pushToEditedModules(module: { id: number, value_rep: string, is_dividend_currency_available: boolean, currency: any}) {
    const arrayIndex = this.editedFigures.findIndex(element => element.module_id === module.id)
    if (arrayIndex !== -1) {
      this.editedFigures.splice(arrayIndex, 1);
    }
    if(module.is_dividend_currency_available){
      this.editedFigures.push({
        module_id: module.id,
        value_rep: module.value_rep,
        currency_id: module.currency ? module.currency.id : null
      });
    } else {
      this.editedFigures.push({
        module_id: module.id,
        value_rep: module.value_rep,
        currency_id:  null
      });
    }
  }
  pushToEditedFigures(figure: { id: number, value_rep: string,is_dividend_currency_available: boolean, currency: any }) {
    const arrayIndex = this.editedFigures.findIndex(element => element.figure_id === figure.id)
    if (arrayIndex !== -1) {
      this.editedFigures.splice(arrayIndex, 1);
    }
    if(figure.is_dividend_currency_available){
      this.editedFigures.push({
        figure_id: figure.id,
        value_rep: figure.value_rep,
        currency_id: figure.currency ? figure.currency.id : null
      });
    } else {
      this.editedFigures.push({
        figure_id: figure.id,
        value_rep: figure.value_rep,
        currency_id:  null
      });
    }
  }
  checkNodeHasMaxValueError(node) {
    let action = 'remove';
    if (node.value_rep) {
      let numberArray = node.value_rep.toString();
      numberArray = numberArray.replace('-', '');
      numberArray = numberArray.split(',');

      if (numberArray.length === 1) {
        if (numberArray[0].length > 15) {
          action = 'add';
        }
      } else if (numberArray.length === 2) {
        if (numberArray[0].length > 15 || numberArray[0].length > 7) {
          action = 'add';
        }
      }
    }
    return action === 'add' ? true : false;
  }
  getDisplayName(kpi?: any) {
    let displayName = this.entity.company.name;
    if (kpi && this.kpiType === 'PeriodKpi') {
      displayName += ' / '
        + this.entity.report_type
        + ', ' + moment(this.entity.report_date).format('yyyy-MM-DD') + '/ Period Kpi  (' +
        moment(kpi.from_date).format('yyyy-MM-DD') +
        ' - ' +
        moment(kpi.to_date).format('yyyy-MM-DD') +
        ' )';
    } else if (kpi && this.kpiType === 'SnapshotKpi') {
      displayName += ' / '
      + this.entity.report_type
      + ', ' + moment(this.entity.report_date).format('yyyy-MM-DD') + '/ Snapshot Kpi  (' +
      moment(kpi.snapshot_date).format('yyyy-MM-DD') +
      ' )';
    }
    return displayName;
  }
  kpiUpdated(kpi) {
    const kpiData = this.kpi.kpiData;
    this.kpi = kpi;
    this.kpi.kpiData = kpiData;
  }
  public toggleType(event?) {
    event.hide = !event.hide;
  }
  getMutations(event) {
    return !event.hide ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg'];
  }
  getName(index) {
    return 'suggest' + index;
  }
  public getKpiData(kpi: any) {
    if (kpi && kpi.kpiData) {
      return;
    }
    kpi.loading = true;
    this.http
      .get(
        CompanyReportsSettings.BASE_URL +
        '/' +
        snakeCase(this.kpiType) +
        '/' +
        kpi.id
      )
      .subscribe(
        (response: any) => {
          response.modules.forEach(module => {
            if (module.value_rep) {
              module.display_value = (module.value_rep / module.multiplier).toString();
            }
          });
          kpi.kpiData = response;
          kpi.loading = false;
        },
        (err) => {
          if (err.status === 404) {
            this.router.navigate([snakeCase(this.kpiType), 'error'], {
              skipLocationChange: true,
            });
          }
          kpi.loading = false;
        }
      );
  }
  getYear(date) {
    if (date) {
      return moment(date).year();
    } else {
      return '';
    }
  }
  isMLLocked(): boolean {
    if(this.entity?.ml_info) {
      if(this.entity.ml_info.ml_request_status !== null && this.entity.ml_info.ml_request_status !== MLSendStatus.FAILED && this.entity.ml_info.ml_request_status !== MLSendStatus.CLOSED){
        return true;
      }
    }
    return false;
  }
  formatTooltip(data) : string {
    if(data) {
      return  (data as string).replace('_', ' ');
    }
    return '';
  }

  _check() {

    // check based kpi type
    if(this.kpiType === 'PeriodKpi'  && this.dateRangePicker.value) {
        this.loadingData = true;
      this.reportservice.getPeriodKpiById(+this.dateRangePicker.value).subscribe((pastPeriodKpi: any) => {
        this.kpiInitialData.past_modules = pastPeriodKpi.modules;
        this.formatPreviousYearData(this.kpiInitialData);
        this.kpi.kpiData.modules = this.kpiInitialData.modules;
        this.kpi.kpiData.past_from_date = pastPeriodKpi.from_date;
        this.kpi.kpiData.past_to_date = pastPeriodKpi.to_date;
        this.processKpiModules();
        this.loadingData = false;
      }, (error)=> {
        console.error(error);
        this.loadingData = false;
      });
    }

    if(this.kpiType === 'SnapshotKpi'  && this.dateRangePicker.value) {
      this.loadingData = true;
      this.reportservice.getSnapshotKpiById(+this.dateRangePicker.value).subscribe((pastSnapshotKpi: any) => {
        this.kpiInitialData.past_modules = pastSnapshotKpi.modules;
        this.formatPreviousYearData(this.kpiInitialData);
        this.kpi.kpiData.modules = this.kpiInitialData.modules;
        this.kpi.kpiData.past_snapshot_date = pastSnapshotKpi.snapshot_date;
        this.processKpiModules();
        this.loadingData = false;
      }, (error) => {
        console.error(error);
        this.loadingData = false;
      });
    }
  }
  formatPreviousYearData(data: any) {
    this.previousYearValues = {};
    this.previousValueSource = {};
    if (data.past_modules && data.past_modules.length > 1) {
      this.formatPreviousYearValue(data.past_modules);
      this.setPreviousYearValue(data.modules);
      data.showPrevious = true;
    } else {
      data.showPrevious = false;
    }
  }
  formatPreviousYearValue(past_moduleList) {
    for (let index = 0; index < past_moduleList.length; index++) {
      this.previousYearValues[past_moduleList[index].module_id] = past_moduleList[index].value_rep;
      this.previousValueSource[past_moduleList[index].module_id] =  past_moduleList[index].value_source;
      if (past_moduleList[index].children && past_moduleList[index].children.length > 0) {
        this.formatPreviousYearValue(past_moduleList[index].children)
      }
    }
  }
  setPreviousYearValue(moduleList) {
    for (let index = 0; index < moduleList.length; index++) {
      moduleList[index].prev_year_value = this.previousYearValues[moduleList[index].module_id];
      moduleList[index].prev_value_source = this.previousValueSource[moduleList[index].module_id];
      if (moduleList[index].children && moduleList[index].children.length > 0) {
        this.setPreviousYearValue(moduleList[index].children)
      }
    }
  }

  updateCurrency(data, module, isReset: boolean) {
    module.showLoader = true;
    let editorData = {};
    if(module.figure_id === null) {
       editorData = {
        editor_data: [
          {
            module_id : module.module_id,
            value_rep: parseFloat(module.value_rep),
            currency_id: isReset ? null : data.id
          }
        ]
      }
    } else {
       editorData = {
        editor_data: [
          {
            figure_id : module.figure_id,
            value_rep: parseFloat(module.value_rep),
            currency_id: isReset ? null : data.id
          }
        ]
      }
    }
    this.reportservice.saveEditordata(editorData, this.kpi.id, snakeCase(this.kpiType)).subscribe((response: any) => {
      if (response) {
        module.currency = data;
        module.showCurrency = false;
        module.showLoader = false;
        this.toastr.success('Updated currency successfully!', this.getKpiType() );
      } else {
        throwError(response);
      }
    }, (error) => {
      console.error(error);
      module.showCurrency = false;
      module.showLoader = false;
      this.toastr.error('Sorry, some error occurred', this.getKpiType() );
    });
  }
  enable_row_highlight(is_highlighted){
    return is_highlighted && this.entity.report_type == 'interim';
  }
  processKpiModules() {
    this.kpi.kpiData.modules.forEach(module => {
      if (module.value_rep !== null && module.value_rep !== undefined && module.value_rep !== '') {
        if (module.multiplier === 0.01 || module.multiplier === '0.01') {
          module.display_value = (module.value_rep / module.multiplier).toFixed(4);
        } else {
          module.display_value = (module.value_rep / module.multiplier).toString();
        }
      }
      if (module.prev_year_value !== null && module.prev_year_value !== undefined && module.prev_year_value !== '') {
        if (module.multiplier === 0.01 || module.multiplier === '0.01') {
          module.prev_display_value = (module.prev_year_value / module.multiplier).toFixed(4);
        } else {
          module.prev_display_value = (module.prev_year_value / module.multiplier).toString();
        }
      }
    });
  }
  appendPreviewUrl(data: any, kpiData) {
    kpiData.previewUrl = data;
  }
  setPreviewMLReportUrlEmpty(kpiData, closeEvent: boolean) {
    if(closeEvent) {
      kpiData.previewUrl = null;
    }
  }
}

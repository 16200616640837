import * as moment from 'moment';
import { environment } from '../environments/environment';
import { ActiveFilter } from './shared/enums/active-filter.enum';

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-DD hh:mm:ss';

export class FrontendSettings {
  public static VIEWS: any = [
    {
      name: 'Entities',
      path: 'entity',
      icon: 'linode'
    },
    {
      name: 'Dashboard',
      path: 'dashboard',
      icon: 'tachometer'
    },
    {
      name: 'Static',
      path: 'static',
      icon: 'gavel'
    },
    {
      name: 'Match',
      path: 'match',
      icon: 'plug'
    },
    {
      name: 'Powers',
      path: 'powers',
      icon: 'hand-rock-o'
    },
    {
      name: 'Calendar',
      path: 'calendar',
      icon: 'calendar'
    },
    {
      name: 'Universes and Tiers',
      path: 'company_institution_lists',
      icon: 'list-ul'
    },
    {
      name: 'Task Manager',
      path: 'task_manager',
      icon: 'list-ul'
    },
    {
      name: 'Document processing',
      path: 'doc_process',
      icon: 'file'
    },
    {
      name: 'Missing Calendar Events',
      path: 'missing-events',
      icon: 'calendar-minus-o'
    },
    {
      name: 'Market cap',
      path: 'marketcap',
      icon: 'pie-chart'
    },
    {
      name: 'Shareholders',
      path: 'shareholders',
      icon: 'group'
    },
    {
      name: 'Company Reports',
      path: 'company_reports',
      icon: 'paperclip'
    },
    {
      name: 'Mozenda',
      path: 'mozenda',
      icon: 'medium'
    },
    {
      name: 'Estimates',
      path: 'estimates',
      icon: 'line-chart'
    },
    {
      name: 'Press Release',
      path: 'press_release',
      icon: 'book'
    }
  ];
}

export class ApiSettings {
  public static DOMAIN: string = environment.domain;
  public static API_HOST: string = environment.api_host;
  public static BASE_URL: string = ApiSettings.API_HOST + '/api';
  public static HORIZON_URL: string = ApiSettings.API_HOST + '/horizon';

  public static META_ENDPOINT = 'meta';
  public static HISTORY_ENDPOINT = 'history';
  public static AUTH_ENDPOINT = 'auth';
  public static ME_ENDPOINT = 'me';
  public static FILE_ENDPOINT = 'file';
  public static FILE_TOKEN_ENDPOINT = 'filetoken';
  public static USERS_ENDPOINT = 'all_users';
  public static BATCH_LOG_ENDPOINT = 'batch_logs';
  public static TEMPORARY_STORAGE = 'temporary_storage';
  public static WEBHOOK_CUSTOMERS_ENDPOINT = 'powers_customer';

  public static RELATION_ADD = 'attach';
  public static RELATION_REMOVE = 'detach';
  public static INCLUDE_ALL = 'include_all_companies';
  public static INTERNAL_SERVER_ERROR = 'Something went wrong. Please contact the administrator.';

  public static RESPONSES: any = {
    VALUE_ERROR: 'VALUE_ERROR',
    ENTITY_OUTDATED: 'ENTITY_OUTDATED',
    PERMISSION_ERROR: 'PERMISSION_ERROR',
    ENTITY_HAS_DEPENDENT_ENTITIES: 'HAS_DEPENDENT_ENTITIES',
    TOKEN_EXPIRED: 'token_expired',
    TOKEN_EXPIRED_MESSAGE: 'Token has expired',
    TOKEN_NOT_PROVIDED: 'token_not_provided',
    TOKEN_NOT_PROVIDED_MESSAGE: 'Token not provided',
    TOKEN_INVALID: 'token_invalid',
    TOKEN_INVALID_MESSAGE: 'Malformed token'
  };

  public static RESPONSE_CODES: any = {
    PERMISSION_ERROR: 403
  };

  public static POWERS: any = [
    {
      name: 'Create indexes',
      endpoint: 'create_indexes',
      method: 'POST'
    },
    {
      name: 'Generate permissions',
      endpoint: 'permission/generate',
      method: 'GET'
    },
    {
      name: 'Update currencies',
      endpoint: 'currency/update',
      method: 'GET'
    },
    {
      name: 'Update geography',
      endpoint: 'country/update',
      method: 'GET'
    },
    {
      name: 'Update languages',
      endpoint: 'language/update',
      method: 'GET'
    },
    {
      name: 'Update exchanges',
      endpoint: 'exchange/update',
      method: 'GET'
    },
    {
      name: 'Update sectors',
      endpoint: 'sector/update',
      method: 'GET'
    },
    {
      name: 'Update instruments',
      endpoint: 'instrument/update',
      method: 'GET'
    },
    {
      name: 'Import Infront Feeds',
      endpoint: 'infront_feed/import',
      method: 'GET'
    },
    {
      name: 'Sync Infront Feeds/Instruments',
      endpoint: 'infront_feed/sync_instruments',
      method: 'GET'
    },
    {
      name: 'Import companies',
      endpoint: 'company/import',
      method: 'GET'
    },
    {
      name: 'Match companies',
      endpoint: 'company/match',
      method: 'GET'
    },
    {
      name: 'Import Number of Shares',
      endpoint: 'share_count/import',
      method: 'GET'
    },
    {
      name: 'Import descriptions',
      endpoint: 'description/import',
      method: 'POST'
    },
    {
      name: 'Update Infront sectors',
      endpoint: 'infront_sector/update',
      method: 'GET'
    },
    {
      name: 'Add quantities',
      endpoint: 'quantity/update_quantities',
      method: 'POST'
    },
    {
      name: 'Add Units',
      endpoint: 'unit/update_units',
      method: 'POST'
    },
    {
      name: 'Sync company universes',
      endpoint: 'company_universe/sync',
      method: 'POST'
    },
    {
      name: 'Sync direkt calendar events',
      endpoint: 'calendar_event/sync_direkt',
      method: 'POST'
    },
    {
      name: 'Create tasks for static data',
      endpoint: 'static/create_tasks',
      method: 'GET'
    },
    {
      name: 'Create tasks for instruments',
      endpoint: 'instrument/create_tasks',
      method: 'GET'
    },
    {
      name: 'Create tasks for calendar',
      endpoint: 'company/create_tasks',
      method: 'GET'
    },
    {
      name: 'Create tasks for company tiers',
      endpoint: 'company_tier/create_tasks',
      method: 'GET'
    },
    {
      name: 'Create tasks for institution tiers',
      endpoint: 'institution_tier/create_tasks',
      method: 'GET'
    },
    {
      name: 'Import Instruments CSV',
      endpoint: 'instrument/import_instruments_csv',
      method: 'GET'
    },
    {
      name: 'Create tasks for Shareholder data',
      endpoint: 'shareholder/create_tasks',
      method: 'GET'
    },
    {
      name: 'Update Taxonomies',
      endpoint: 'company_report/update_taxonomies',
      method: 'GET'
    },
    {
      name: 'Update CIK codes',
      endpoint: 'company/update_cik_code',
      method: 'GET'
    }
  ];
  public static ENTITIES_WITH_IS_ACTIVE_FILTER: any = [ActiveFilter.DirektProfile]
}

export class MatchSettings {
  public static BASE_URL: string = ApiSettings.BASE_URL + '/instrument';
  public static COUNTS_ENDPOINT = 'counts';
  public static UNMATCHED_ENDPOINT = 'unmatched';
  public static MATCH_ENDPOINT = 'match';
  public static MARK_AS_INACTIVE_ENDPOINT = 'inactive';
  public static INCONSISTENT_ENDPOINT = 'inconsistent';
}

export class StaticSettings {
  public static TIER_ID = environment.company_static_tier;
  public static BASE_URL: string = ApiSettings.BASE_URL + '/static';
  public static COUNTS_ENDPOINT = 'counts';
  public static MISSING_ENDPOINT = 'missing';
  public static REVISIONS_ENDPOINT = 'revisions';
  public static APPROVE_ENDPOINT = 'approve';
  public static COMPANY_ENDPOINT = 'company';
  public static MISSING_DONE_ENDPOINT = 'created';
  public static REQUIRED_COMPANY_POSITIONS: any = ['CEO', 'Chairman'];
  public static POSITIONS_ENDPOINT = 'positions';
  public static ADVISORS_ENDPOINT = 'advisors';
  public static DESCRIPTIONS_ENDPOINT = 'description';
  public static COMPANY_DATA_ENDPOINT = 'company_data';
  public static SECTOR_ENDPOINT = 'sectors';
}

export class ShareholderSettings {
  public static TIER_ID = environment.company_shareholders_tier;
  public static BASE_URL: string = ApiSettings.BASE_URL + '/shareholder';
  public static COUNTS_ENDPOINT = 'counts';
  public static MISSING_ENDPOINT = 'missing';
  public static REVISIONS_ENDPOINT = 'outdated';
  public static COMPANY_ENDPOINT = 'company';
}
export class MissingCompanyEventsSettings {
  public static TIER_ID = environment.company_calendar_tier;
  public static BASE_URL: string = ApiSettings.BASE_URL + '/company';
  public static COUNTS_ENDPOINT = 'missing_events_count';
  public static MISSING_ENDPOINT = 'missing_events';
  public static COMPANY_ENDPOINT = 'missing_events';
  public static MISSING_DONE_ENDPOINT = 'created';
  public static EARNINGS_EVENT_TYPE = 'Earnings';
  public static MISSING_EVENTS_DEFAULT_FROM_DATE = moment().startOf('day')
  public static MISSING_EVENTS_DEFAULT_TO_DATE = moment().add(3, 'months').endOf('day')
}

export class MissingInstitutionEventsSettings {
  public static TIER_ID = environment.institution_calendar_tier;
  public static BASE_URL: string = ApiSettings.BASE_URL + '/institution';
  public static COUNTS_ENDPOINT = 'counts';
  public static MISSING_ENDPOINT = 'missing_events';
  public static COMPANY_ENDPOINT = 'missing_events';
  public static MISSING_DONE_ENDPOINT = 'created';
}

export class CalendarSettings {
  public static TIER_ID = environment.company_calendar_tier;
  public static INSTITUITION_TIER_ID = environment.institution_calendar_tier;
  public static MISSING_ENDPOINT = 'missing_events';
  public static MISSING_FIXED_ENDPOINT = 'missing_events_fixed';
  public static CONFIRM_ENDPOINT = 'confirm_events';
  public static CONFIRM_FIXED_ENDPOINT = 'confirm_events_fixed';
  public static HISTORICAL_CONFIRM_FIXED_ENDPOINT =
    'historical_confirm_fixed_events';
}

export class MarketcapSettings {
  public static TIER_ID = environment.company_marketcap_tier;
  public static BASE_URL: string = ApiSettings.BASE_URL + '/share_count';
  public static COUNTS_ENDPOINT = 'counts';
  public static COMPANY_ENDPOINT = 'company';
  public static MISSING_ENDPOINT = 'missing';
  public static OUTDATED_ENDPOINT = 'outdated';
  public static DUPLICATE_PRIMARIES_ENDPOINT = 'duplicate_primaries';
  public static MISSING_PRIMARIES_ENDPOINT = 'missing_primaries';
}

export class MozendaDeliveriesSettings {
  public static BASE_URL: string = ApiSettings.BASE_URL + '/mozenda';
  public static COUNTS_ENDPOINT = 'counts';
  public static DELIVERIES_ENDPOINT = 'deliveries';
  public static PRODUCTION_ENDPOINT = 'production';
}

export class ListsSettings {
  public static TIER_MISSING_COMPANIES_ENDPOINT = 'missing_companies';
  public static TIER_MISSING_INSTITUTIONS_ENDPOINT = 'missing_institutions';
  public static TIER_ADDITIONAL_COMPANIES_ENDPOINT =
    'additional_companies';
  public static TIER_ADDITIONAL_INSTITUTIONS_ENDPOINT =
    'additional_institutions';
  public static UNIVERSE_SYNC_COMPANIES_ENDPOINT = 'sync';
  public static ASSIGN_MANAGER_TO_MISSING_COMPANIES_ENDPOINT =
    'assign_manager';
}
export class CompanyReportsSettings {
  public static TIER_ID = environment.company_report_tier;
  public static BASE_URL: string = ApiSettings.BASE_URL + '/company_report';
  public static COMPANY_ENDPOINT = 'company';
  public static MISSING_REPORTS_ENDPOINT =  CompanyReportsSettings.BASE_URL + '/missing_reports';
  public static MISSING_EVENTS_ENDPOINT =  CompanyReportsSettings.BASE_URL + '/missing_events';
  public static KEY_FINANCIAL_ENDPOINT = CompanyReportsSettings.BASE_URL + '/key_financials';
  public static MASK: any = {
    mask: Number,
    thousandsSeparator: ' ',
    scale: 7,
    radix: '.',
    mapToRadix: [','],
    min: -999999999999999,
  };

}
export class AuthSettings {
  public static WHITELISTED_DOMAINS: any = [ApiSettings.DOMAIN];
  public static BLACKLISTED_ROUTES: any = [
    ApiSettings.BASE_URL + '/' + ApiSettings.AUTH_ENDPOINT
  ];

  public static HEADER_NAME = 'Authorization';
  public static HEADER_PREFIX = 'Bearer ';
  public static TOKEN_NAME = 'token';
  public static SUPER_USER_ROLE = 'superuser';
  public static REFRESH_TOKEN_ENDPOINT = 'refresh_token';
  public static REDIRECT_KEY = 'RedirectUrl';

  public static getToken() {
    return localStorage.getItem(AuthSettings.TOKEN_NAME);
  }

  public static setToken(token) {
    localStorage.setItem(AuthSettings.TOKEN_NAME, token);
  }

  public static emptyToken() {
    localStorage.removeItem(AuthSettings.TOKEN_NAME);
  }
}
export class AutomatedContentSettings {
  public static BASE_URL = environment.api_host+ '/api/az/';

  public static MOZENDA_URL_PRODUCTION_EUR = AutomatedContentSettings.BASE_URL + 'infront-mozenda-dep-prod-eu/connector?';
  public static MOZENDA_URL_PRODUCTION_USA =  AutomatedContentSettings.BASE_URL + 'mozendadepprod2/connector?';
  public static MOZENDA_URL_STAGE =  AutomatedContentSettings.BASE_URL + 'mozendadep/connector?';
  public static MOZENDA_URL_LOCAL = 'http://localhost:7071/api/connector?';

  public static DOC_PROCESSING_URL_DEV = AutomatedContentSettings.BASE_URL + 'infront-doc-processing-dev/connector?';
  public static DOC_PROCESSING_URL_TEST = AutomatedContentSettings.BASE_URL + 'infront-doc-processing-test/connector?';
  public static DOC_PROCESSING_URL_PREVIEW = AutomatedContentSettings.BASE_URL + 'infront-doc-processing-preview/connector?';
  public static DOC_PROCESSING_URL_LABELING =  AutomatedContentSettings.BASE_URL + 'infront-doc-processing-labeling/connector?';
  public static DOC_PROCESSING_URL_PRODUCTION =  AutomatedContentSettings.BASE_URL + 'infront-doc-processing-prod3/connector?';
}
export class TranslationSettings {
  public static TRANSLATION_ENDPOINT = 'description/translate'
  public static LANGUAGE_LIST_ENDPOINT = 'language/translate_languages'
  public static COMPANY_ENDPOINT = 'company';
  public static SAVE_ALL = 'description/store_all';
  public static DESCRIPTIONS_ENDPOINT = 'description';
}

export class EstimatesSettings {
  public static BASE_URL: string = ApiSettings.BASE_URL + '/estimates';
  public static CONSENSUS_TYPE_OPTIONS = [
    { id: "PRE", name: "PRE" },
    { id: "POST", name: "POST" }
  ]
  public static DECIMAL_REGEX = /^\d*\.?\d+$/;
  public static DECIMAL_WITH_NEGATIVE_REGEX = /^-?\d*\.?\d+$/;
  public static INTEGER_REGEX = /^\d+$/;
  public static VALUE_MASK: any = {
    mask: Number,
    signed: true,
    thousandsSeparator: '',
    radix: '.',           
    scale: 20,            
    normalizeZeros: true
  };
}

export class PressReleaseAttachmentSettings {
  public static MAX_FILE_SIZE = 102400; //100 MB, used as a backup option in case of missing rules from the backend
  public static VALID_EXTENSIONS = ['pdf', 'zip', 'html', 'xml', 'xlsx', 'json', 'jpeg', 'png']; //Used as a backup option in case of missing rules from the backend
  public static INVALID_SIZE_ERROR_KEY = 'INVALID_SIZE';
  public static INVALID_EXTENSION_ERROR_KEY = 'INVALID_EXTENSION';
}

export class CalendarEventSettings {
  public static CALENDAR_EVENT_PREFILL_DATA = {
    date_confirmed: true,
    date_approximation_id: environment.date_approx_object.id,
    time_approximation_id: environment.time_approx_object.id,
    to_date: null,
    source_type: environment.calendar_event_default_source_type
  };
  public static CALENDAR_EVENT_ENTITY_KEY = 'CalendarEvent';
  public static CALENDAR_EVENT_COMMENT_DEFAULT_LANG = environment.calendar_event_comment_default_lang;
  public static CALENDAR_FULL_DAY_EVENT_FIELD = {
    type: "boolean",
    disabled: false,
    show_in_table: true,
    show_in_store: true,
    show_in_update: true,
    key: "full_day_event",
    triggerEventOnClick: true,
    label: "Full day event",
    rules: [
        "boolean",
        "nullable"
    ]
  }
  public static CALENDAR_EVENT_TYPE_BOTH_KEY = 'Both'
  public static CALENDAR_EVENT_AUTO_TRANSLATE_MESSAGE = "If a comment is added in Swedish or Norwegian, an English translation will be automatically created and saved."
  public static CALENDAR_RECURRING_EVENT_FIELD = {
    type: "enum",
    disabled: false,
    show_in_table: false,
    show_in_store: true,
    show_in_update: true,
    key: "recurring_event",
    label: "Recurring event",
    prefill: "",
    rules: [],
    values: [
        { value: "", label: "Does not repeat" },
        { value: "weekly", label: "Weekly" },
        { value: "monthly", label: "Monthly" },
        { value: "quarterly", label: "Quarterly" },
        { value: "yearly", label: "Yearly" }
    ]
  }
  public static CALENDAR_EVENT_MAX_RECURRING_COUNT = {
    weekly: 52,
    monthly: 24,
    quarterly: 10,
    yearly: 5
  } 
  public static KEYS_TO_DELETE_FROM_PAYLOAD = [
    'recurring_event', 'occurrences', 'full_day_event', 
    'is_selected', 'is_edited', 'is_child_edited', 
    'updated_at', 'revised_at', 'calendar_event_type', 
    'date_approximation', 'time_approximation', 'language', 
    'direkt_profile', 'owner', 'company_id', 'adjusted'
  ]
  public static LANGUAGE_ID_REQUIRED_ERROR_MESSAGE = 'The language is required when comment is present' 
  public static CALENDAR_EVENT_CREATE_PERMISSION_ERROR = "You don't have permission to create events in this tier."
  public static REPORT_DATES_CALENDAR_EVENT_TYPE = 'Earnings'
  public static REPORT_DATES_DIREKT_PROFILE_ID = environment.report_dates_default_profile_id
  public static REPORT_DATES_TIME_APPROXIMATION_ID = environment.report_dates_time_approximation_id
  public static REPORT_DATES_DEFAULT_TIMEZONE = 'Europe/Stockholm'
}

export class featureFlagSettings{
  public static BASE_URL: string = ApiSettings.BASE_URL + '/feature_flag';
  public static FEATURE_KEYS = {
    'estimates_manual_onboarding': 'estimates-manual-onboarding',
  }
}
 <nav class="nav flex-column" *ngIf="report">
  <div class="report-menu">
    <a class="nav-link back-to-reports" (click)="backToReports()">
      <i class="fa fa-chevron-circle-left"></i> Back to reports
    </a>
  </div>
  <div class="report-menu d-flex justify-content-between position-relative" (click)="checkTaxonomy('report-overview', false)">
    <a class="nav-link w-100" [ngClass]="{'active': selectedMenu === 'report-overview'}" [href]="'company_reports/' + companyId + '/report/' + report.id + '/report-overview'" (click)="$event.preventDefault()">
      Report Overview
    </a>
    <a class="nav-link custom-icon-pos">
      <div *ngIf="report?.created_by" [routerLink]="['/entity/user', report?.locked_by]" class="user-profile-link-custom">
        <con-avatar [userId]="report?.created_by" [size]="20" [placement]="'right'" [tooltipPrefix]="'Created by'" [container]="'body'"></con-avatar>
      </div>
    </a>
  </div>
  <div class="report-menu" (click)="checkTaxonomy('income-statement', false)" >
    <a class="nav-link" [ngClass]="{'active': selectedMenu === 'income-statement'}" [href]="'company_reports/' + companyId + '/report/' + report.id + '/income-statement'" (click)="$event.preventDefault()">
      Income Statement
    </a>
  </div>
  <div class="report-menu" (click)="checkTaxonomy('balance-sheet', false)" >
    <a class="nav-link" [ngClass]="{'active': selectedMenu === 'balance-sheet'}" [href]="'company_reports/' + companyId + '/report/' + report.id + '/balance-sheet'" (click)="$event.preventDefault()">
      Balance Sheet
    </a>
  </div>
  <div class="report-menu" (click)="checkTaxonomy('cash-flow', false)" >
    <a class="nav-link" [ngClass]="{'active': selectedMenu === 'cash-flow'}" [href]="'company_reports/' + companyId + '/report/' + report.id + '/cash-flow'" (click)="$event.preventDefault()">
      Cash Flow
    </a>
  </div>
  <div class="report-menu" (click)="checkTaxonomy('period_kpi', false)" >
    <a class="nav-link" [ngClass]="{'active': selectedMenu === 'period_kpi'}" [href]="'company_reports/' + companyId + '/report/' + report.id + '/period_kpi'" (click)="$event.preventDefault()">
      Period Kpi
    </a>
  </div>
  <div class="report-menu" (click)="checkTaxonomy('snapshot_kpi', false)" >
    <a class="nav-link" [ngClass]="{'active': selectedMenu === 'snapshot_kpi'}" [href]="'company_reports/' + companyId + '/report/' + report.id + '/snapshot_kpi'" (click)="$event.preventDefault()">
      Snapshot Kpi
    </a>
  </div>
  <div class="report-menu" (click)="checkTaxonomy('shareholders', false)" >
    <a class="nav-link" [ngClass]="{'active': selectedMenu === 'shareholders'}" [href]="'company_reports/' + companyId + '/report/' + report.id + '/shareholders'" (click)="$event.preventDefault()">
      Shareholders
    </a>
  </div>
  <div class="report-menu" (click)="checkTaxonomy('employees', false)" >
    <a class="nav-link" [ngClass]="{'active': selectedMenu === 'employees'}" [href]="'company_reports/' + companyId + '/report/' + report.id + '/employees'" (click)="$event.preventDefault()">
      Employees
    </a>
  </div>
  <div class="report-menu" (click)="checkTaxonomy('key-financials', false)">
    <a class="nav-link" [ngClass]="{'active': selectedMenu === 'key-financials'}" [href]="'company_reports/' + companyId + '/report/' + report.id + '/static'" (click)="$event.preventDefault()">
      Key Financials
    </a>
  </div>
   <!-- commented for future deprecation -->
<!--  <div class="report-menu" (click)="checkTaxonomy('static', false)">-->
<!--    <a class="nav-link" [ngClass]="{'active': selectedMenu === 'static'}" [href]="'company_reports/' + companyId + '/report/' + report.id + '/static'" (click)="$event.preventDefault()">-->
<!--      Static-->
<!--    </a>-->
<!--  </div>-->
   <div class="report-menu" (click)="checkTaxonomy('report-dates', false)">
     <a class="nav-link" [ngClass]="{'active': selectedMenu === 'report-dates'}" [href]="'company_reports/' + companyId + '/report/' + report.id + '/report-dates'" (click)="$event.preventDefault()">
       Report Dates
     </a>
   </div>

  <a class="nav-link back-to-reports ml-adjust-btn" (click)="openSendToMLPopup()">Send To ML  <span class="spinner-border spinner-border-sm" *ngIf="showMlLoader"></span></a>

  <a class="nav-link back-to-reports d-flex justify-content-between align-items-center ">
    <div *ngIf="!lockLoader && report && report.id">
      <label class="switch">
        <input type="checkbox" [checked]="lockReportFlag" (click)="lockReport()">
        <span class="slider round"></span>
      </label>
      <span class="switch-text" >
                {{getLockText()}}
              </span>
    </div>
    <div *ngIf="lockLoader || !report?.id">
      <div class="spinner-border spinner-border-sm"></div>
      <span *ngIf="!report?.id">Please wait...</span>
      <span *ngIf="report?.id">{{lockReportFlag ? 'Locking...' : 'Unlocking...'}}</span>
    </div>
    <div *ngIf="!lockLoader && report?.locked_by" [routerLink]="['/entity/user', report?.locked_by]" class="user-profile-link-custom">
      <con-avatar [userId]="report?.locked_by" [size]="20" [placement]="'right'" [tooltipPrefix]="'Locked by'"></con-avatar>
    </div>
  </a>

   <a class="nav-link back-to-reports adjust-mt-5 small-icon" [ngbTooltip]="report?.key_financial_only? 'Key Financial Only':'Not Key Financial Only'" >
     <div *ngIf="!lockLoader && report && report.id">

      <button class="nav-key-financials">

       <con-icon-by-name iconName="close" [mutations]="['solid','2xs']" *ngIf="!report?.key_financial_only"></con-icon-by-name>
       <con-icon-by-name iconName="check" [mutations]="['solid','2xs']" *ngIf="report?.key_financial_only"></con-icon-by-name>

      </button>
       <span class="key-financials-status-icon-pos" >Key Financial Only</span>
     </div>
   </a>
</nav>

import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, AbstractControl} from '@angular/forms';
import { ValidatorConversionService } from '../../services/validator-conversion.service';
import { EntityDescription, EntitiesService } from '../../services/entities.service';
import { ProfileService } from '../../../auth/services/profile.service';
import { EntityConfirmModalComponent } from '../../components/views/entity-confirm-modal.component';
import { snakeCase } from 'change-case';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { prettyCase } from 'app/shared/utilities.classes';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'con-entity-form-content-table',
    templateUrl: './entity-form-content-table.component.html',
    styleUrls: ['./entity-form-content-table.component.scss']
})

export class EntityFormContentTableComponent implements OnInit, OnDestroy {
    @Input() entityName: string;
    @Input() entity: any;
    @Input() entityList = [];
    @Input() revision = false;
    @Input() fixedValues: any = {};
    @Input() removeRelations = false;
    @Input() canStore: boolean;
    @Input() canEdit: boolean;
    @Input() canShow: boolean;
    @Input() canDelete: boolean;
    @Input() emptyAfterSave = false;
    @Input() except: any = [];
    @Input() only: any = [];
    @Input() hidden = false;
    @Input() showWhenChanged = true;
    @Input() toastMessage = '';
    @Input() fromCalendar = false;
    @Input() showEditButton = false;
    @Input() ownerType: string;
    @Input() isLocked = false;
    @Output() afterSave: EventEmitter<any> = new EventEmitter<any>();
    @Output() afterDelete: EventEmitter<any> = new EventEmitter<any>();
    @Output() showComments: EventEmitter<any> = new EventEmitter<any>();
    @Output() addNewRow: EventEmitter<any> = new EventEmitter<any>();

    public isSaving: boolean;
    private hasErrors: boolean;
    private formStructure: any = {};
    public fieldErrors: any = [];
    public generalErrors: any = [];
    public generalSaveAllErrors: any = [];
    public entityDescription: EntityDescription;
    public entityForm: UntypedFormGroup;
    public entityDescriptions: any = {};
    private fixedValuesForChildForms: any = {};
    private oppositeRelations: any = {};
    public selectedIndex = -1;
    public fields: any = [];
    public obj: any;
    public loading: boolean;
    public progressLoader = false;
    public resetForm = true;
    public _canStore: boolean;
    public _canEdit: boolean;
    public _canShow: boolean;
    public _canDelete: boolean;
    private profileReadySubscription: ISubscription;
    private controlValueChangeSubscription: ISubscription[] = [];
    public mask: any = {
        mask: Number,
        thousandsSeparator: ' ',
        radix: '.',
        mapToRadix: [','],
    }
    constructor(private service: EntitiesService,
        private formBuilder: UntypedFormBuilder,
        private validatorConverter: ValidatorConversionService,
        private profileService: ProfileService,
        private toastr: ToastrService,
        private router: Router,
        private modalService: NgbModal) { }

    isLoading() {
        return this.service.isLoading();
    }

    generatePermissions() {
        this._canShow = (this.canShow === undefined) ? this.profileService.can
            (this.profileService.getPermissionName(this.entityName, 'show')) : this.canShow;
        this._canStore = (this.canStore === undefined) ? this.profileService.can
            (this.profileService.getPermissionName(this.entityName, 'store')) : this.canStore;
        this._canEdit = (this.canEdit === undefined) ? this.profileService.can
            (this.profileService.getPermissionName(this.entityName, 'update')) : this.canEdit;
        this._canDelete = (this.canDelete === undefined) ? this.profileService.can
            (this.profileService.getPermissionName(this.entityName, 'delete')) : this.canDelete;
    }

    ngOnInit() {
        this.profileReadySubscription = this.profileService.whenReady()
            .subscribe(res => {
                if (res) {
                    this.generatePermissions();
                }
            });
        this.router.events.subscribe(route => {
            this.setFormResetFlag(true);
        });
        this.loading = true;
        if (!this.entityList) {
            this.entityList = [];
        }
        this.service.getEntityDescriptionByEntityName(this.entityName)
            .subscribe(entityDescription => {
                this.entityDescription = entityDescription;
                if (this.resetForm) {
                    this.createForm();
                }
                this.prepareRelations();
                this.loading = false;
                this.setFormResetFlag(false);
            });
    }

    ngOnDestroy() {
        this.profileReadySubscription.unsubscribe();
    }

    setFormResetFlag(value: boolean) {
        this.resetForm = value;
    }

    prepareRelations() {
        this.oppositeRelations = {};
        this.entityDescription.getBelongsToRelationsForForm().forEach(relation => {
            this.oppositeRelations[relation.name] = { required: false };
            this.service.getInverseRelations(relation)
                .subscribe(relations => {
                    this.oppositeRelations[relation.name] = relation
                });
            if (relation.model && !this.entityDescriptions[relation.model]) {
                this.service.getEntityDescriptionByEntityName(relation.model)
                    .subscribe(entityDescription => {
                        this.entityDescriptions[relation.model] = entityDescription;
                    });
            }
        });
    }
    createForm(): void {
        this.formStructure = {};
        this.hasErrors = false;
        this.fieldErrors = [{}];
        this.generalErrors = [
            []
        ];
        this.fields = [];
        this.getFields().forEach(field => {
            this.fields.push(field);
            if (this.fixedValues && !this.fixedValues[field.key]) {
                const structure = [];
                // Default value
                structure.push({ value: '', disabled: this.entityAlreadyExists(0) ? field.disabled : false });
                const validators = [];
                field.rules.forEach(rule => {
                    const validator = this.validatorConverter.getValidatorByString(rule);
                    if (validator) {
                        validators.push(validator);
                    }
                })
                structure.push(validators);
                this.formStructure[field.key] = structure;
            }
        });

        const FormGroupFields = this.formBuilder.group(this.formStructure);
        this.entityForm = this.formBuilder.group({
            name: this.entityName,
            items: this.formBuilder.array([])
        });
        if (this.entityList) {
            this.entityList.forEach(item => {
                const itemForm = this.formBuilder.group(item);
                this.items.controls.push(itemForm);
                this.fieldErrors.push({});
                this.generalErrors.push([]);
            });
        }
        if (!this.isLocked) {
            this.items.controls.push(FormGroupFields);
        }
    }

    get items(): UntypedFormArray {
        return this.entityForm.get('items') as UntypedFormArray
    }

    revert(index: number) {
        this.setFormResetFlag(true);
        this.items.controls[index] = this.formBuilder.group(this.formStructure);
    }

    entityAlreadyExists(index: number) {
        return this.entityList && this.entityList[index] && this.entityList[index].id !== undefined;
    }
    editMode(index: number) {
        return this.entityList[index].editMode;
    }
    editEvent(index: number, value: boolean = true) {
        this.entityList[index].editMode = value;
    }
    getBelongsToRelations() {
        return this.entityDescription.getBelongsToRelationsForForm()
            .filter(r => this.shouldShowRelation(r));
    }
    shouldShowRelation(relation: any) {
        return (this.only.length ? this.only.indexOf(relation.name) > -1 : true)
            && this.except.indexOf(relation.name) === -1;
    }
    getFields() {
        const fields = this.entityDescription.getFieldsForUpdateForm();
        return fields.filter(field => {
            return this.only.length ? this.only.indexOf(field.key) > -1 : true
        })
            .filter(field => {
                return this.except.indexOf(field.key) === -1;
            });
    }

    delete(index: number) {
        const modalRef = this.modalService.open(EntityConfirmModalComponent,
            {
                size: 'md'
            });

          const data = {
            title: prettyCase(this.entityName),
            message: 'Are you sure you want to delete ?',
            alert: false
          }

          modalRef.componentInstance.modalData = data;
          modalRef.result.then((result) => {
            this.deleteConfirmed(index);
          }, (reason) => {
          });
    }
    deleteConfirmed(index: number) {
            this.isSaving = true;
            this.selectedIndex = index;
            this.progressLoader = true;
            this.service.deleteEntity(this.entityDescription.name, this.entityList[index])
                .subscribe(
                    res => {
                        this.entityList.splice(index, 1);
                        this.items.removeAt(index);
                        this.isSaving = false;
                        this.hasErrors = false;
                        this.fieldErrors.splice(index, 1);
                        this.generalErrors.splice(index, 1);
                        this.generalSaveAllErrors = [];
                        this.afterDelete.emit(res);
                        this.toastr.success('Deleted successfully!', prettyCase(this.entityName));
                        this.progressLoader = false;
                        if(!this.items.controls.length) {
                          this.addRow();
                        }
                    },
                    error => {
                        this.progressLoader = false;
                        this.hasErrors = true;
                        this.isSaving = false;
                    });
    }
    fieldIsRequired(key) {
        let ret = false;
        this.getFields().forEach(f => {
            if (f.key === key) {
                ret = f.rules.indexOf('required') > -1;
            }
        });
        return ret;
    }
    isFormArrayValid(i: number) {
        return (<UntypedFormArray>this.entityForm.get('items')).controls[i].pristine;
    }

    onSubmit(index: number) {
        this.selectedIndex = index;
        this.progressLoader = true;
        this.generalErrors = [];
        this.generalSaveAllErrors = [];
        this.isSaving = true;
        const entity: any = {};
        this.items.updateValueAndValidity();
        this.resetRowValidation(index);

        if (this.entityAlreadyExists(index)) {
            entity['id'] = this.entityList[index].id;
        }
        for (const key in (<UntypedFormGroup>this.items.controls[index]).controls) {
            if ((<UntypedFormGroup>this.items.controls[index]).controls.hasOwnProperty(key)) {
                const control = (<UntypedFormGroup>this.items.controls[index]).controls[key];
                if (control.dirty) {
                    if (this.entity) {
                        // Check if value is dirty
                        if (control.value !== this.entity[key]) {
                            entity[key] = control.value;

                            // Check for a new empty value
                        } else if (control.value === '' && this.entity[key] !== '') {
                            entity[key] = control.value;
                        }
                    } else {
                        if (control.value !== '') {
                            entity[key] = control.value;
                        }
                    }
                }

                // Field is required, include it if we do not exists
                if (this.fieldIsRequired(key)) {
                    entity[key] = control.value;
                }
                if (this.only.indexOf(key) > -1) {
                    entity[key] = control.value;
                }
            }
        }
        Object.keys(this.fixedValues).forEach(field => {
            entity[field] = this.fixedValues[field];
        });
        if (this.entityDescription.name === 'Owner') {
            if (entity.capital !== null && entity.capital !== undefined && entity.capital !== '') {
                if (entity.capital.toString().length) {
                    entity.capital = (entity.capital / 100).toFixed(4);
                }
            }
            if (entity.votes !== null && entity.votes !== undefined && entity.votes !== '') {
                if (entity.votes.toString().length) {
                    entity.votes = (entity.votes / 100).toFixed(4);
                }
            }
        }
        if(!!entity.capital === false) {
          this.fieldErrors[index]['capital'] = ['Capital must be a number.'];
        }
        if(!!entity.name === false) {
          this.fieldErrors[index]['name'] = ['Name field is required.'];
        }
        let hasDuplicateError = false;
        if(entity.name) {
          if(entity.id === undefined) {

          }
          this.items.value.forEach((item, idx) => {
            if(item.name === entity.name && index !== idx) {
              this.fieldErrors[index]['name'] = ['Duplicate owner name not allowed.'];
              hasDuplicateError = true;
            }
          })
        }
        if(!!entity.capital === false || !!entity.name === false || hasDuplicateError) {
          this.progressLoader = false;
          this.items.updateValueAndValidity();
          return;
        }

        this.service.saveEntity(this.entityDescription.name, entity)
            .subscribe(
                res => {
                    this.progressLoader = false;
                    if (this.removeRelations) {
                        this.entityDescription.getAllRelations().forEach(relation => {
                            if (res[relation.name]) { delete res[relation.name]; }
                        });
                    }
                    if (this.entityAlreadyExists(index)) {
                        this.toastr.success(
                            'Updated successfully!', prettyCase(this.entityName)
                        );
                    } else {
                        if (this.fromCalendar) {
                            this.service.calendarEditingSubject.next(this.entityName);
                        }
                        this.toastr.success(
                            'Saved successfully!', prettyCase(this.entityName)
                        );
                    }
                    this.entityList[index] = res;
                    this.entityList[index].editMode = false;
                    this.isSaving = false;
                    this.hasErrors = false;
                    this.afterSave.emit(this.entityList);
                    this.resetRowValidation(index);
                    if (this.entityName === 'Owner') {
                        res.capital = (res.capital !== null && res.capital !== '') ? (res.capital * 100).toFixed(2) : null;
                        res.votes = (res.votes !== null && res.votes !== '') ? (res.votes * 100).toFixed(2) : null;
                    }
                },
                err => {
                    this.progressLoader = false
                    if (err.isValueError()) {
                        this.fieldErrors[index] = err.getData().fields;
                        this.generalErrors[index] = err.getData().general;
                        this.hasErrors = true;
                    }
                    this.isSaving = false;
                }
            )
    }
    resetRowValidation(index) {
      this.fieldErrors[index]['votes'] = [];
      this.fieldErrors[index]['capital'] = [];
      this.fieldErrors[index]['shares'] = [];
      this.fieldErrors[index]['name'] = [];
    }
    pushToRelation(relation: any, obj: any) {
        if (!this.entity[snakeCase(relation.name)]) {
            this.entity[snakeCase(relation.name)] = [];
        }
        this.entity[snakeCase(relation.name)].push(obj);
    }
    spliceFromRelation(relation: any, obj: any) {
        const indx = this.entity[snakeCase(relation.name)].findIndex(o => o.id === obj.id);
        this.entity[snakeCase(relation.name)].splice(indx, 1);
    }
    resetErrors(key: string, value: string) {
        if (value) {
            this.fieldErrors[key] = [];
        }
    }
    resetErrorsForRelation(relation: any, value: any) {
        this.resetErrors(relation.foreign_key, value.model);
        if (value.type) {
            this.resetErrors(relation.foreign_type, value.type);
        }
    }
    addRow() {
        this.items.controls.push(this.formBuilder.group(this.formStructure));
        this.fieldErrors.push({});
        this.generalErrors.push([]);
        this.generalSaveAllErrors = [];
    }
    removeRow(index: number) {
        this.items.removeAt(index);
        this.fieldErrors.splice(index, 1);
        this.generalErrors.splice(index, 1);
        this.generalSaveAllErrors = [];
    }

    getFixedSearchParams(relation: any) {
        const obj = {};
        const oppositeRelation = this.getOppositeRelation(relation);
        if (relation.type === 'hasOne') {
            obj[relation.foreign_key] = 'f:null';
        }
        if (oppositeRelation) {
            if (relation.type === 'hasMany') {
                obj[oppositeRelation.name + ':'] = 'f:null'
            }
        }
        return obj;
    }
    getOppositeRelation(relation) {
        return this.oppositeRelations[relation.name];
    }

  saveAll() {
    this.progressLoader = true;
    // Clear visible errors
    for (let i = 0 ; i < this.fieldErrors.length; i++) {
      this.fieldErrors[i] = {};
    }
    for (let i = 0 ; i < this.generalErrors.length; i++) {
      this.generalErrors[i] = [];
    }
    this.generalSaveAllErrors = [];

    // format data for BE
    const formattedData = {
      editor_data: []
    };

    // here this.items means complete form
    // validate form & show error in appropriate row
    this.items.controls.forEach((ownerControl: AbstractControl, index: number) => {
      const entity = JSON.parse(JSON.stringify(ownerControl.value));

      if (entity.capital !== null && entity.capital !== undefined && entity.capital !== '') {
        if (entity.capital.toString().length) {
          entity.capital = (entity.capital / 100).toFixed(4);
        }
      }
      if (entity.votes !== null && entity.votes !== undefined && entity.votes !== '') {
        if (entity.votes.toString().length) {
          entity.votes = (entity.votes / 100).toFixed(4);
        }
      }
      const dataWithRequiredKeys = {
        name: entity.name,
        capital: entity.capital,
        shareholder_id: this.fixedValues.shareholder_id,
        shares: entity.shares,
      }
      if (this.entityAlreadyExists(index)) {
        dataWithRequiredKeys['id'] = this.entityList[index].id;
      }
      if((entity.name || entity.shares || entity.capital || entity.votes) && !entity.id ) {
        formattedData.editor_data.push(dataWithRequiredKeys);
      }
      if(entity.id) {
        formattedData.editor_data.push(dataWithRequiredKeys);
      }

    });


    if(!formattedData.editor_data.length) {
      this.toastr.info("Please add value to at least one row.","Owners");
      this.progressLoader = false;
      return;
    }
    // filter duplicate owner name count
    const names = formattedData.editor_data.map(o => o.name)
    const filtered = formattedData.editor_data.filter(({name}, index) => !names.includes(name, index + 1));
    if(filtered.length !== formattedData.editor_data.length){
      this.toastr.error("Please remove duplicate names for owners name column.","Owners");
      this.progressLoader = false;
      return;
    }
    // handle after save
    this.service.saveAllOwnersInShareholder(this.fixedValues.shareholder_id, formattedData).subscribe((response: any) => {

      if (this.entityName === 'Owner') {
        response.owners.forEach((entity) => {
          entity.capital = (entity.capital !== null && entity.capital !== '') ? (entity.capital * 100).toFixed(2) : null;
          entity.votes = (entity.votes !== null && entity.votes !== '') ? (entity.votes * 100).toFixed(2) : null;
        });
      }

      response.owners.forEach((entity, index: number) => {
        this.entityList[index] = entity;
        this.entityList[index].editMode = false;
      });

      this.isSaving = false;
      this.hasErrors = false;
      this.progressLoader = false;
      this.toastr.success("Updated successfully.","Owners");
      this.afterSave.emit(this.entityList);
    }, (error) => {
      if (error.isValueError()) {
        this.toastr.error("Could not update, some errors in form.", "Owners");
        this.formatEditorErrors(error);
      }
      this.progressLoader = false;
      this.isSaving = false;
    });
  }
  // function to format error message specific to row items, and also show general errors
  formatEditorErrors(error){
    // format field errors
    if(error.getData().fields) {
      for (const errorKey in error.getData().fields) {
        const errorMetaData = (errorKey as string).split('.');
        errorMetaData.splice(0,1);
        let index = parseInt(errorMetaData[0]);
        // create error object to specific row
        // formatting error string
        const errorString = (error.getData().fields[errorKey][0] as string).split('.')[2];
        const formattedErrorString = [errorString.charAt(0).toUpperCase() + errorString.slice(1) + '.'];
        this.fieldErrors[index][errorMetaData[1]] = [formattedErrorString];
      }
    } else if(error.getData().message) {
      this.generalSaveAllErrors = error.getData().message;
     }
  }

}
